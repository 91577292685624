$border-radius: 5px;
$border-color: #d1c5c5;

.unit-checbox {
  display: flex;
  justify-content: center;
  margin-right: 8px;

  .unit-checbox-item {
    cursor: pointer;
    border: solid 1px #d1c5c5;
    transition: 0.2s;
    font-size: 7px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 4px;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active {
      background-color: #0063fd;
      color: white;
    }
    &:hover:not(.active) {
      background-color: $border-color;
    }
  }
}
