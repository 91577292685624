
.sliderPanel
{
  background-color: rgb(228 230 242 / 35%);
  .boxSliderPanel {
    width: 487px;
    display: flex;
    flex-direction: column;
    flex:1;
  
    .header {
      color: rgba(46, 58, 148, 1);
    }
    form {
      display: flex;
      flex-direction: column;
      flex:1;
      .slideTemplate {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
      }
    }
    .drawer-para {
      margin: 20px 0px;
      color: black;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      text-align: center;
      margin-top: -15px;
    }
    .addSlide-card {
      cursor: pointer;
      padding: 0px 0px;
      width: 170px;
      height: 155px;
      border: 1px solid #c6cae2
    }
    .image-wrap {
      padding: 0px 12px;
    }
  }
}

.activeTemp {
  background-color: #cfd3f1 !important;
}
