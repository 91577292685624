$head-height: 70px;

.previewDialog {
  min-width: 60%;
  max-width: 80%;
  margin: auto;

  .dialogBody {
    overflow: hidden;
    text-align: center;

    img {
      max-width: 100%;
      max-height: calc(100vh - 112px);
    }
  }
}

.preview-head {
  width: 100%;
  background-color: #eef3ff;
  display: flex;

  align-items: center;

  &.justify {
    justify-content: center;
  }

  .text {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #2e3a94;
  }

  svg {
    color: #2e3a94;
    font-size: 14px;
  }

  .MuiSelect-select {
    color: #2e3a94 !important;
    font-size: 14px !important;
    font-weight: bold;
  }

  .MuiFormControl-root .MuiOutlinedInput-root {
    background-color: transparent !important;
    height: 44px !important;
  }

  .actions {
    position: absolute;
    right: 5%;
  }
}

.previewDialog {
  .preview-wrapper {
    min-height: calc(100vh - $head-height);
  }
}

.preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;

  .preview-slide {
    background: white;
    background-image: none;
    width: 100%;

    .editable:hover {
      outline: none;
    }

    .carousel-root {
      height: 100%;
      display: flex;
      justify-content: center;

      .carousel-slider,
      .slider-wrapper,
      .slider,
      .slide {
        width: 100%;
        text-align: initial;
        height: calc(100vh - 80px - 115px);

        .item {
          position: relative;
          max-height: 100%;
          max-width: 90%;
          overflow: auto;

          .item-message {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          .cs-slide {
            position: relative;
            overflow: hidden;
            transition: all 250ms ease-in-out;
            border: solid 1px #e6e6e6;
          }
        }

        .item-loading {
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 600;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          z-index: 5;
        }

        .control-dots {
          left: 0;
          bottom: -3px;
          left: 0;

          .dot {
            box-shadow: none;
            background-color: black;
          }
        }

        .carousel-status {
          text-shadow: none;
          color: #2e3a94;
          top: auto;
          font-weight: 600;
          bottom: -9px;
          right: 21px;
          font-size: 11px;
        }
      }

      .control-arrow {
        top: 50%;
        background-color: black;
        font-size: 26px;
        bottom: auto;
        opacity: 0.4;

        &::after,
        ::before {
          border-color: blue;
        }

        &:hover {
          opacity: 1;
        }

        &.control-next.control-arrow {
          right: 24px;
        }

        &.control-prev.control-arrow {
          left: 24px;
        }
      }

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.preview-loading {
      pointer-events: none;

      .control-arrow {
        background-color: rgb(99, 99, 99);
        opacity: 0.2;
      }
    }

    .carousel-slider {
      position: static !important;
    }
  }
}

.preview-kpi {
  height: 100vh;

  .preview-slide {
    .carousel-root {
      .carousel-slider,
      .slider-wrapper,
      .slider,
      .slide {
        height: calc(100vh - 80px);
      }
    }
  }
}
