.unitBox {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .header-left {
      width: 80%;
      display: flex;
      justify-content: space-around;
    }
    button {
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      &:hover {
        color: #2cc1d6;
      }
      &.active {
        color: #2cc1d6;
        text-decoration: underline;
      }
    }
  }

  .boxRow {
    display: flex;
    width: 100%;

    .box {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        input {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child {
        button {
          border: 1px solid #d5dadf;
          color: #fff;
          font-size: 20px;
          background-color: #a4afb6;
          cursor: pointer;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      input {
        border: 1px solid #d5dadf;
        border-right: 0;
        margin-bottom: 6px;
        outline: none;
        width: 100%;
        text-align: center;
        padding: 10px;
        color: #6d7882;
      }
      label {
        font-size: 9px;
        color: #a6a6a6;
        text-transform: uppercase;
      }
    }

    .magnetBox {
      button {
        width: 100%;
        height: 38px;
        transition: all 0.4s;
        &.deactive {
          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
          }
        }
        &.active {
          background-color: #2cc1d6;
        }
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
