@import "./variables.scss";

.control {
  // margin-top: 20px;
  .body .control .body {
    padding: 0 !important;
  }
  .head {
    font-size: 14px;
    font-weight: 600;
  }

  .body {
    margin: 10px 0;
    padding: 0px 10px !important;
  }

  .MuiOutlinedInput-root {
    background: none !important;
    // width: 125px;
  }

  .divider {
    width: 95%;
    margin: auto;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(188, 192, 221, 0) 0%,
      #bcc0dd 20.11%,
      #bcc0dd 80.43%,
      rgba(188, 192, 221, 0.15625) 99.04%
    );
  }

  // input {
  //   background: none;
  //   border: 1px solid #d5dadf;
  //   margin: auto 4px;
  //   padding: 4px 0;
  //   outline: none;
  //   font-family: Lato;
  //   font-size: 13px;
  //   font-weight: normal;
  //   color: grey;
  // }

  label,
  .propertyLabel {
    font-size: 13px;
    font-weight: 500;
    color: $color9;
  }

  label {
    display: inline-block;
    &.error {
      text-align: left;
      color: #f00000;
    }
  }
}
.control_accordian {
  border-top: 1px solid rgba(220, 220, 220, 0.01);
  box-shadow: none !important;
  .Mui-expanded.MuiAccordionSummary-root {
    background-color: rgba(239, 239, 239, 0.505);
  }
}

.alignment {
  .iconBtns {
    .icon {
      color: $color7;
      padding: 6px;
    }
  }
}

.background {
  .bgColor {
    padding: 0;
    border: none;
    outline: none;
    background: none;
    border-width: 0;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }

  .bgColor.mini {
    width: 20px;
    height: 20px;
    display: inline-block;
  }
}

.layerOpacity {
  // .opacitySlider {
  //   width: 75%;
  //   margin-left: 2px;
  // }

  .opacityLabel {
    float: right;
    font-size: 12px;
    font-weight: 700;
    color: $color3;
  }

  .MuiSlider-track {
    color: $color7;
  }

  .MuiSlider-thumb {
    color: $color7;
  }
}

// .styles {
//   .inputs {
//     align-items: center;
//     margin-bottom: 6px;

//     input,
//     label {
//       display: inline-block;
//     }

//     label {
//       width: 30%;
//     }

//     input {
//       width: 65%;
//     }

//     &.zindex {
//       label {
//         width: 70%;
//       }

//       input {
//         width: 25%;
//       }
//     }
//   }
// }

.text {
  margin-top: 0 !important;

  .font-family-color {
    display: flex;
    align-items: center;

    .font-color {
      padding: 0;
      border: none;
      outline: none;
      background: none;
      border-width: 0;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .font-weight-bold {
    display: flex;
  }

  .iconBtns {
    .icon {
      font-size: 18px;
    }
  }
}

// .actions {
//   margin-top: 0 !important;

//   .slide {
//     display: flex;
//     align-items: center;

//     div {
//       width: 47%;

//       img {
//         width: 50%;
//         border-radius: 8px;
//       }
//     }
//   }
// }

.selectBox {
  display: flex;
  align-items: center;
  .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  span {
    width: 70%;
    font-size: 12px;
    font-weight: 500;
    color: $color3;
  }

  div {
    align-items: baseline;
  }
}

.animation {
  .play {
    display: flex;
    align-items: center;

    button {
      color: $color7;
      font-size: 25px;
    }
  }

  .duration,
  .delay {
    input {
      width: 40%;
    }
  }
}

/*jodit text editor*/
.jodit-toolbar-editor-collection {
  background: #fff;
}

/*table*/
table p {
  margin: 0px;
}

.border-row {
  margin: 10px 0;

  input {
    width: 50px;
  }
}

.text-right {
  text-align: right;
}

.checkboxProperty,
.radioProperty {
  .checkbox-row {
    .checkbox {
      height: 20px !important;
      width: 20px !important;
      flex-basis: auto !important;
      border-color: #000;
      cursor: pointer;

      &::after {
        left: 8px !important;
        top: 4px !important;
        width: 3px !important;
        height: 8px !important;
        border-width: 0 2px 2px 0 !important;
      }
    }

    .label-input-checkBox {
      width: 70%;
      border: 0;
      padding-left: 2px;
      margin-left: 5px;
      font-size: 12px;
      outline: 0;
      border-bottom: 1px solid #0000001f;
      padding-bottom: 2px;
    }

    .bgColor {
      display: inline-block;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
    .css-15v22id-MuiAccordionDetails-root {
      padding: 0;
    }
    .addItem-input {
      width: 90%;
      border: 1px solid #00000126;
      outline: 0;
      padding: 5px;
    }
    .addNew-btn {
      width: 100%;
      padding: 5px;
    }
  }

  .duplicateicon {
    position: absolute;
    right: 25px;
    color: #2e2222eb;
    top: 18px;
    &.Mui-disabled {
      color: #74545475;
    }
  }
  .removeIcon {
    position: absolute;
    right: 8px;
    color: #d20000eb;
    &.Mui-disabled {
      color: #7c000075;
    }
  }

  .addItem {
    padding-left: 9px !important;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    input::placeholder {
      color: #c2c2c2;
    }

    button {
      min-width: auto;
      padding: 2px 5px;
      border-radius: 2px;
      border-radius: 5px;
      padding: 5px 20px;
    }
  }
  .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px;
  }
  .image_upload_btn {
    padding: 8px 0px;
    margin: 8px 0px 5px 0px;
    display: flex;
    justify-content: center;
    width: 90%;
    font-size: 13px;
  }
}

.radioProperty {
  .radio-row {
    position: relative;
    display: flex;
    align-items: center;

    .radio {
      cursor: pointer;
    }

    input.label {
      width: 70%;
      border: 0;
      margin-left: 5px;
      font-size: 12px;
      outline: 0;
      border-bottom: 1px solid #0000001f;
      padding-bottom: 2px;
    }
    .addItem-input {
      width: 90%;
      border: 1px solid #00000126;
      outline: 0;
      padding: 5px;
    }
    .addNew-btn {
      width: 100%;
      padding: 5px;
    }
  }
}

#showTable {
  width: 100%;

  tbody {
    border: inherit;

    tr {
      border: inherit;

      td {
        border: inherit;
        position: relative;

        div div {
          padding: 10px;
        }

        .tabledropdown {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0px;
          padding: 0px 10px;
          z-index: 1;

          & > li {
            text-align: right;
            margin-top: 10px;

            &:hover ul.items {
              display: block;
            }
          }

          li ul.items {
            padding: 0px;
            width: 100%;
            box-shadow: -3px 2px 10px #beb4b4;
            display: none;

            li {
              background: #fff;
              padding: 6px 20px;
              text-align: left;
              cursor: pointer;

              &:hover {
                background-color: #f0f0f0;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.audio {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: $color3;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .currentTime {
    display: flex;
    justify-content: space-between;

    * {
      width: 31%;
    }
  }

  .timeWrapper {
    display: flex;
    width: 20%;

    div {
      display: grid;
      width: 50%;
    }
  }

  .uploadBtn {
    margin: 0px 0px 10px;
  }

  .controlColor {
    width: 20px;
    height: 25px;
    padding: 0;
  }
}

//typography component
.inputProperty {
  .typographygrid {
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;

    .fontsizeinput {
      width: 65px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.graph-property {
  table {
    tr {
      td {
        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
        }

        &.background {
          width: 35px;
        }
      }

      td,
      th {
        &:first-child {
          min-width: auto;
        }

        border: solid 1px rgb(209, 209, 209);
        padding: 3px 5px 3px 15px;
        min-width: 80px;
        position: relative;

        &:last-child {
          border: none;
        }
      }

      .delete-column {
        position: absolute;
        right: -7px;
        top: 0px;
        padding: 1px 6px;
        color: #fb0606bf;
      }

      .delete-icon {
        color: #c70000;
      }

      .Mui-disabled {
        color: #7436367e;
      }
    }
  }

  .graph-buttons {
    margin-top: 20px;

    button {
      margin-right: 10px;
      font-size: 10px;
      padding: 5px 10px;
    }
  }

  .MuiDialogContent-root.css-1h8qeq2-MuiDialogContent-root {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 430px;
  }
}

// Border

.borderProperties,
.paddingProperties {
  .radiusIcon,
  .paddingIcon {
    font-size: 20px;
  }

  .paddingInput {
    width: 100%;
  }
}

.flipster-property {
  .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
    margin-right: 5px;
  }

  .borderShow {
    border: solid 1px #ededed !important;
  }

  .accordian {
    box-shadow: none;
    border: none;

    .accordianTxt {
      p {
        width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &::before {
      background-color: rgba(239, 239, 239, 0.505);
    }

    .border-bottom::after {
      content: "";
      bottom: 0;
      position: absolute;
      width: 91%;
      border: solid 1px #7e7e7e0d;
    }

    .image {
      position: relative;

      img {
        display: block;
      }

      &:hover {
        .upload {
          opacity: 1;
        }
      }
    }

    .delete-icon {
      margin-top: 8px;
    }

    input,
    .textArea {
      width: 100%;
      border: solid 1px rgba(46, 58, 148, 0.1490196078);
      padding: 10px 10px;
      color: #2e3a94;
      margin-bottom: 10px;
      outline: none;
    }
  }

  .add-btn {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .text-right {
    display: flex;
    justify-content: flex-end;
  }

  .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 0;
  }
}

.image-bg {
  position: relative;
  width: 100%;
  height: 140px;

  img {
    height: 100%;
    display: block;
    object-fit: contain;
    background: gray;
    object-fit: cover;
    border-radius: 5px;
  }

  button:hover {
    background-color: #00000088;
  }

  .upload {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    background-color: #00000091;
    opacity: 0.7;

    .upload-icon {
      color: white;
      font-size: 40px;
    }
  }

  &:hover {
    .upload {
      opacity: 1;
    }
  }
}

//background
.background {
  .warningAlert {
    color: #72706d;
    font-size: 11px;
  }

  .backgroundselectbox {
    justify-content: space-between;
    align-items: center;

    .css-dm8ds6-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0;
    }

    .image {
      position: relative;

      img {
        display: block;
      }

      .upload {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        opacity: 0;
        background: #00000085;

        .upload-icon {
          color: white;
          font-size: 40px;
        }
      }

      &:hover {
        .upload {
          opacity: 1;
        }
      }
    }

    .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0;
    }

    .degreeInput {
      width: 65px;
    }

    .slider {
      width: 90%;
    }

    .colorLable {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: $color3;
    }

    .checkboxes {
      padding-top: 0;
      padding-bottom: 0;
    }

    .imageUploadgrid {
      display: flex;
      justify-content: center;

      .image {
        width: 80%;
      }
    }
  }

  .disableBackground {
    label h3 {
      color: #bbbbbb !important;
    }
  }

  .add-btn {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.bgControl {
  .control {
    margin: 0;
  }
  .checkboxes {
    padding: 2px;
  }
  .warningAlert {
    color: #72706d;
    font-size: 11px;
  }
}

#propertise_container {
  position: relative;
}

.layer_footer {
  width: 100%;
  background: #f0f0f0;
  bottom: 0px;
  padding: 2px;
  margin-top: 12px;
  border-radius: 5px;
}

//box Shadow
.boxShadow {
  .boxShadowCheckbox {
    justify-content: space-between;
    align-items: center;

    .css-dm8ds6-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0;
    }
  }
  .boxShadowPropertiesContainer {
    display: flex;
    align-items: center;
    // gap: 3.2rem;
    label {
      min-width: 25%;
    }
    input[type="number"] {
      width: 75%;
    }
  }
  .css-faw8os-MuiGrid-root {
    margin: 0;
  }

  h4 {
    color: #4b4545;
  }

  .disable {
    color: gray;

    h4 {
      color: gray;
    }

    input,
    label {
      color: gray;
    }

    input[type="color"] {
      position: relative;

      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        content: "";
        background: #ffffffa8;
      }
    }
  }
}

.tabGroup {
  .activeTab {
    background: #2e3a94;
    color: white;
    margin-bottom: 1px;

    &:hover {
      background: #2e3a94;
      color: white;
      margin-bottom: 1px;
    }
  }

  .tabButton {
    border: 1px solid #d5dadf;
    border-radius: 4px;
    display: flex;
    margin: 6px 0;

    .tabBtn {
      justify-content: flex-start;
      margin: 0;
      border-radius: 4px;
    }

    .MuiButton-text {
      width: 100%;
    }

    .MuiIconButton-root {
      font-size: 15px;
    }
  }

  .addTabBtn {
    display: flex;
    margin: 12px auto 0;
    padding: 4px 14px;
    width: fit-content;
  }

  .editControls {
    padding: 20px 0;

    .textWrapper {
      display: flex;

      label {
        width: 70%;
      }

      input {
        width: 100%;
        border-bottom: 1px solid #2e3a94;
      }
    }
  }

  .tabgroup_direction {
    .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.animation {
  .animationproperties {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .animationCheckbox {
    .css-dm8ds6-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0;
    }
  }

  .animationplayCheckbox {
    .css-dm8ds6-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0px 15px;
    }
  }

  .animationdropdown {
    .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 5px 0;
    }
  }

  .sub-heading {
    font-size: 12px;
  }
}

.actions {
  .selected-btn {
    display: block;
    font-size: 10px !important;
    text-transform: none !important;
    margin: 8px 0 !important;

    img {
      width: 70%;
      max-height: 70px;
    }
  }

  .css-ocs8jh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .error {
    color: red;
    margin: 11px 0px;
  }
}

.control_accordian {
  .accordian_heading {
    min-height: 40px !important;
    padding: 0px 10px;
  }
  .accordian_content {
    margin: 0 !important;
  }
  .accordian_details {
    padding: 0;
  }
}

.grid-property,
.column-property {
  .removeIcon {
    color: #c70000;

    &.Mui-disabled {
      color: #7436367e;
    }
  }

  .add-btn {
    margin-top: 15px;
  }
}

.text-center {
  text-align: center;
}

.social-icons {
  .MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
  }
  .social-links {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        width: 100%;
      }
    }
    .MuiPaper-root {
      position: relative;
      padding: 10px;
      margin-bottom: 10px;
      .cancelIcon {
        position: absolute;
        right: 7px;
        top: 6px;
        cursor: pointer;
        color: red;
      }
      .cancelIcon.disbled {
        color: gray;
      }
    }
    input {
      margin: 0;
      width: 100%;
      padding: 10px;
      background-color: #f9f9f9;
    }
  }
  .socialicons-container {
    .icons {
      display: flex;
      gap: 0.2rem;
      img {
        max-width: 30px;
        max-height: 30px;
        cursor: pointer;
      }
    }
  }
}

.flex-properties {
  .MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.column-property {
  .add-column-section {
    padding: 7px !important;
    margin-top: 16px;
    border: solid 1px #0000003b;
    border-radius: 3px;
  }
  .MuiSelect-select {
    padding: 0;
  }
  .delete-warning {
    color: #e69000;
    margin-top: 8px;
  }
  h3 {
    margin-bottom: 10px;
  }
}

.animationControl {
  .checkBox {
    padding: 2px;
  }
  .selectBox {
    .MuiSelect-select {
      padding-bottom: 2px;
      padding-top: 2px;
    }
  }
}

.alignButtons {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 30px;

  .title {
    h5 {
      margin: 0 !important;
    }
  }
}

.Ckeditor {
  .ck.ck-editor__editable_inline {
    border: 1px solid #c4c4c4;
    border-top: 0;
  }
  .ck-toolbar__items {
    .ck-button {
      font-size: 10px;
      padding: 0;
    }
    .ck-list__item {
      .ck-button {
        padding: 0;
      }
    }
    .ck-color-table {
      button {
        font-size: 10px;
      }
    }
  }
}

// .jodit-wysiwyg {
//   * {
//     font-size: 20px !important;
//   }
// }
.activeLayers {
  .form {
    border: 1px solid lightgray;
    width: 100%;
    border-radius: 0;
    font-weight: 600;
    text-transform: uppercase;
    .MuiInputBase-input {
      padding: 6px;
    }
  }
}

.row-property {
  margin-bottom: 5px;
  p {
    font-size: 12px;
    font-weight: 500;
    color: #718096;
    display: flex;
    align-items: center;
    height: 100%;
  }
  h3 {
    color: black;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .input {
    border: 1px solid #d5dadf;
    margin: auto 3px;
    padding: 4px 0;
    outline: none;
    font-size: 12px;
    font-weight: normal;
    color: grey;
    padding-left: 6px;
    width: 60%;
    right: 0;
  }
  .fullwidth-input {
    width: 100% !important;
  }
  .timer-input {
    padding-left: 0px;
    width: 25%;
    text-align: center;
  }
  .select_box_icon {
    right: 0;
  }
  .select_box_input {
    padding: 3.75px 27px 4px 5px;
      text-transform: capitalize;
  }
  .color {
    background: none;
    margin-right: 2px;
    width: 35%;
    padding: 0px;
    border: 0px;
    height: 25px;
  }
  .text-right {
    display: flex;
    justify-content: flex-end;
  }
  .slider {
    margin-right: 4px;
    border-radius: 12px;
    box-sizing: content-box;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #2e3a94;
    -webkit-tap-highlight-color: transparent;
    height: 2px;
    width: 100%;
    padding: 10px 0;
  }
  .heading_check_box {
    padding: 2.5px 0px;
  }
}

.global-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  padding: 5px 3px;
  button {
    border-radius: 5px;
    padding: 5px 20px;
  }
  .icon {
    margin-left: 8px;
  }
}

.activeTextAlign {
  background-color: rgb(44 58 157 / 33%) !important;
}

.activeEleAlign {
  background-color: rgb(44 58 157 / 33%) !important;
}

.list {
  .list-item-row {
    position: relative;
    padding: 5px 3px;
    .accordian_container {
      border: 1px solid #d5dadf;
      box-shadow: none;
    }
    .Mui-expanded {
      .accordian_heading {
        border-bottom: 1px solid #d5dadf;
      }
    }
    .MuiCollapse-wrapperInner {
      padding: 10px 10px 5px;
    }
    .list_accordian_heading {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-Box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding: 0px 40px 0px 0px;
      color: black;
      text-transform: none;
    }
    .list_input {
      width: 100%;
      font-size: 12px;
      padding: 6px;
      border: 1px solid #d5dadf;
      outline: none;
      color: gray;
      margin-bottom: 10px;
    }
    .subheading_list {
      color: gray;
      margin-bottom: 5px;
    }
    .duplicateicon {
      position: absolute;
      right: 25px;
      color: #2e2222eb;
      top: 16px;
      &.Mui-disabled {
        color: #74545475;
      }
    }
    .removeIcon {
      position: absolute;
      right: 8px;
      color: #d20000eb;
      top: 16px;
      &.Mui-disabled {
        color: #7c000075;
      }
    }
  }
  .alignment_container {
    .iconBtn {
      font-size: 16px;
      border: 1px solid #d5dadf;
      border-radius: inherit;
      padding: 5px;
    }
  }
}

.active_layer_dropdown {
  margin: 5px 10px;
  .active_layer_select {
    color: grey;
    border: 1px solid #d5dadf;
    width: 100%;
    border-radius: 0;
    font-weight: 600;
    text-transform: uppercase;
  }
  .active_layer_input {
    padding: 6px;
  }
}
