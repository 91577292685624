.preview-email {
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  .data {
    width: 800px;
    height: 1800px;
    max-height: 100%;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .mobile-sm {
    width: 320px;
  }
  .desktop {
    width: 600px;
  }
}
.activeDevice {
  background-color: rgba(0, 0, 100, 0.1) !important;
}
