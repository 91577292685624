@import "variables";
@import url("https://cdnjs.cloudflare.com/ajax/libs/jquery.flipster/1.1.5/jquery.flipster.css");
.cs-layer {
  position: absolute;
}
.cs-layer img {
  user-select: none;
  -webkit-user-drag: none;
}
[class*="cs-"]:not(.cs-col) {
  width: 100%;
}
.cs-text {
  width: 100%;
  border-radius: inherit;
  background: none;
  border: 0px;
  padding: 10px;
  margin: 0px;
  background-color: transparent;
}
.cs-text p {
  margin: 0px;
  word-break: break-all;
}
.cs-text, .jodit-wysiwyg {
  p{
    margin-top: 0px;
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  pre{
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.cs-list ul {
  margin: 0;
}
.cs-button {
  padding: 10px;
  border-radius: 6px;
  outline: none;
  border: none;
  background-color: #00b7ff;
  color: white;
  border-radius: inherit;
}
.cs-button * {
  margin: 0px;
}
.cs-button p {
  word-break: break-all;
}
.cs-image {
  vertical-align: middle;
  border-radius: inherit;
  pointer-events: unset !important;
}
.image-wrap {
  height: calc(100% - 45px);
  background: #31b2f5 4f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cs-table {
  width: 100%;
}
.cs-table thead {
  border: inherit;
  height: 50%;
}
.cs-table tbody {
  border: inherit;
  height: 100%;
}
.cs-table tr {
  border: inherit;
}
.cs-table tr td,
.cs-table tr th {
  border: inherit;
}
.cs-flipCard {
  border-radius: inherit;
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
}
.cs-flipCard .head {
  // display: flex;
}
.cs-flipCard .head .flipButton {
  font-size: 30px;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top:0;
  cursor: pointer;
  background: #9c88883d;
  color: #fff;
  opacity:0;
}
.cs-flipCard:hover .flipButton{
  opacity: 1;
}
#mainWrapper .cs-flipCard .head .flipButton{
  display: none;
}
.cs-flipCard .flipCardInner {
  position: relative;
  border-radius: inherit;
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.cs-flipCard .flipCardFront,
.cs-flipCard .flipCardBack {
  position: absolute;
  border-radius: inherit;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
}
.cs-flipCard .flipCardFront .cardBody,
.cs-flipCard .flipCardBack .cardBody {
  // height: 100%;
  // overflow: hidden;
}
.cs-flipCard .flipCardFront {
  background-color: #bbb;
  color: black;
}
.cs-flipCard .flipCardBack {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}
.cardBody {
  display: flex;
  border-radius: inherit;
  padding:10px
}
.cardBody .cs-text {
  padding: 12px;
  width: 50% !important;
}
.cardBody .cs-text p {
  width: 100%;
}
.cardBody img {
  align-self: center;
  aspect-ratio: 1;
  width: 50% !important;
}
.checkbox-container,
.cs-checkboxes {
  border-radius: inherit;
}
.checkbox-container .checkbox-row,
.cs-checkboxes .checkbox-row {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0px;
}
.checkbox-container .checkbox-row .checkbox,
.cs-checkboxes .checkbox-row .checkbox {
  position: relative;
  display: inline-flex;
  border-radius: 4px;
  max-width: 38px;
}
.checkbox-container .checkbox-row .checkbox.checked svg path:last-child,
.cs-checkboxes .checkbox-row .checkbox.checked svg path:last-child {
  d: path(
    "M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7.003 13l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"
  );
}
.checkbox-container .checkbox-row .label,
.cs-checkboxes .checkbox-row .label {
  padding-left: 8px;
  word-break: break-all;
}
.checkbox-container.outlined .checkbox-row .checkbox svg path:last-child,
.cs-checkboxes.outlined .checkbox-row .checkbox svg path:last-child {
  d: path(
    "M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5z"
  );
}
.checkbox-container.outlined
  .checkbox-row
  .checkbox.checked
  svg
  path:last-child,
.cs-checkboxes.outlined .checkbox-row .checkbox.checked svg path:last-child {
  d: path(
    "M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6.003 11L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
  );
}
.cs-col .cs-checkboxes .checkbox-row .checkbox {
  width: 16px;
}
.cs-audio {
  display: flex;
  align-items: center;
  border-radius: inherit;
  background: rgba(227, 251, 255, 1);
  border-radius: 8px;
  aspect-ratio: 10;
  padding: 30px 20px;
}
.cs-audio * {
  margin: 4px;
}
.cs-audio .timeline {
  --sliderColor: rgba(38, 67, 181, 1);
  width: 100%;
  height: 6px;
  appearance: none !important;
  -webkit-appearance: none;
}
.cs-audio .timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none !important;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: var(--sliderColor);
  cursor: pointer;
}
.cs-audio .timeline::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none !important;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: var(--sliderColor);
  cursor: pointer;
}
.cs-audio button {
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  color: #2980b9;
}
.cs-audio .time {
  display: flex;
  align-items: center;
}
.cs-audio .time span {
  font-size: 16px;
}
.fullScreenLayer {
  width: 100% !important;
  height: 100% !important;
  z-index: 100 !important;
  top: 0 !important;
  left: 0 !important;
}
.fullScreenVideo {
  height: 100% !important;
}
.cs-video {
  display: flex;
  background: rgba(0, 0, 0, 1);
  flex-direction: column;
}
.cs-video video {
  width: 100%;
  height: 100%;
  display: block;
}
.cs-video .videoControls {
  align-items: center;
  aspect-ratio: 10;
  transition: all 0.7s;
}
.cs-video .timeline {
  --sliderColor: rgba(38, 67, 181, 1);
  width: 100%;
  height: 6px;
  appearance: none !important;
  -webkit-appearance: none;
}
.cs-video .timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none !important;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: var(--sliderColor);
  cursor: pointer;
}
.cs-video .timeline::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none !important;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: var(--sliderColor);
  cursor: pointer;
}
.cs-video button {
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
}
.cs-video svg {
  pointer-events: none;
}
.cs-video .time {
  display: flex;
  align-items: center;
}
.cs-video .time span {
  font-size: 16px;
}
.cs-input {
  border: 0;
  background: transparent;
}
.cs-radio-container,
.radioProperty {
  border-radius: inherit;
}
.cs-radio-container .radio-row,
.radioProperty .radio-row {
  position: relative;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.cs-radio-container .radio-row .radio,
.radioProperty .radio-row .radio {
  max-width: 40px;
  display: inline-flex;
}
.cs-col .cs-radio-container .radio-row .radio,
.cs-col .radioProperty .radio-row .radio {
  width: 16px;
}
.cs-radio-container .radio-row .radio svg,
.radioProperty .radio-row .radio svg {
  margin-bottom: -3px;
}
.cs-radio-container .radio-row .radio svg path:first-child,
.radioProperty .radio-row .radio svg path:first-child {
  display: none;
}
.cs-radio-container .radio-row .radio.checked svg,
.radioProperty .radio-row .radio.checked svg {
  margin-bottom: -3px;
}
.cs-radio-container .radio-row .radio.checked svg path:first-child,
.radioProperty .radio-row .radio.checked svg path:first-child {
  display: block;
}
.cs-radio-container .radio-row .label,
.radioProperty .radio-row .label {
  width: fit-content;
  word-break: break-all;
  margin-left: 8px;
}
.radioProperty .radio-row .radio {
  width: 16px;
}
.cs-slider {
  position: relative;
  height: 100%;
}
.cs-slider .track,
.cs-slider .thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.cs-slider .track {
  width: 100%;
  background-color: black;
  height: 20%;
  border-radius: 5px;
}
.cs-slider .thumb {
  background: black;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
#dropzone {
  overflow: hidden;
  position: relative;
}
.cs-coverflow,
.cs-carousel {
  overflow-y: hidden;
  border-radius: inherit;
  transition: 0.4s;
}
.cs-coverflow .flipster__item,
.cs-carousel .flipster__item {
  width: 100%;
}
.cs-coverflow .flipster__item .flipster__item__content,
.cs-carousel .flipster__item .flipster__item__content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #707070;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cs-coverflow .flipster__item .flipster__item__content .subtitle,
.cs-carousel .flipster__item .flipster__item__content .subtitle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
.cs-coverflow .flipster__item .flipster__item__content .subtitle .text,
.cs-carousel .flipster__item .flipster__item__content .subtitle .text {
  width: 85%;
  text-align: center;
  white-space: initial;
  word-break: break-word;
}
.cs-coverflow .flipster__item .flipster__item__content .subtitle .heading,
.cs-carousel .flipster__item .flipster__item__content .subtitle .heading {
  margin: 5px 0;
  width: 85%;
  color: #0cf;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
.cs-coverflow .flipster__button,
.cs-carousel .flipster__button {
  color: white;
  font-size: 8px;
  padding: 50px 16px;
  top: calc(50% - 63.5px);
}
.cs-coverflow .flipster__button.flipster__button--next,
.cs-carousel .flipster__button.flipster__button--next {
  right: -15px;
}
.cs-coverflow .flipster__button.flipster__button--prev,
.cs-carousel .flipster__button.flipster__button--prev {
  left: -15px;
}
.cs-carousel .flipster__item {
  background-color: black;
}
.cs-coverflow {
  height: 100%;
}
.cs-coverflow .flipster__container {
  height: 100%;
}
.cs-coverflow .flipster__container .flipster__item {
  width: 68%;
  height: 100%;
  min-width: 330px;
  min-height: 290px;
}
.cs-coverflow .flipster__container .flipster__item .flipster__item__content {
  flex-direction: column;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
.cs-coverflow
  .flipster__container
  .flipster__item
  .flipster__item__content
  .text {
  color: white;
  width: 100%;
  text-align: center;
  margin: 0 !important;
  padding: 10px 5px;
  background-color: #003252;
  word-break: break-word;
}
.cs-coverflow
  .flipster__container
  .flipster__item
  .flipster__item__content
  img {
  min-height: 90%;
  object-fit: cover;
}
.cs-extendedCard {
  border-radius: inherit;
}
.cs-tabGroup {
  /* Style the tab */
  border-radius: inherit;
  /* Style the tab content */
}
.cs-tabGroup .tabs {
  overflow: hidden;
  border: 1px solid #ccc;
  /* Style the buttons inside the tab */
}
.cs-tabGroup .tabs .tabLinks {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}
.cs-tabGroup .tabs .activeBtnHover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.cs-tabGroup .cs-tabContent {
  min-height: 200px;
  height: calc(100% - 107px);
  border: 1px solid #ccc;
  border-top: none;
  position: relative;
}
.cs-tabGroup .cs-tabContent:not(.activeTab) {
  display: none;
}

.cs-tabGroup .cs-tabContent .cs-image {
  height: 100%;
}
/*popup component start*/
.popup-component {
  transition: opacity 0.4s linear !important;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}
.cs-popup {
  position: relative;
  border-radius: inherit;
}
.cs-popup .closePop {
  cursor: pointer;
  position: absolute;
  top: -41px;
  right: -46px;
  background: none;
  border: none;
  outline: none;
  font-size: 20px;
}
.hidden-pop {
  display: none;
}
.visuallyhidden-pop {
  opacity: 0;
}
/*popup component end*/
#sitemap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
  z-index: 2000;
}
#sitemap .sitemapModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.752);
}
#sitemap .sitemapModal .modal {
  position: relative;
  width: 80vw;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
  max-width: 800px;
  padding: 15px;
}
#sitemap .sitemapModal .modal .close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px 10px;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
}
#sitemap .sitemapModal .modal .close-button:hover {
  background-color: #ebebeb;
}
#sitemap .sitemapModal .modal .content {
  height: 100%;
}
#sitemap .sitemapModal .modal .content .heading {
  font-weight: 600;
  margin-top: 8px;
  padding-bottom: 10px;
  border-bottom: solid 1px #000 26;
}
#sitemap .sitemapModal .modal .content .slidesList {
  display: flex;
  flex-wrap: wrap;
  max-height: 90%;
  overflow: auto;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box {
  width: 172px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 9px;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box.active {
  pointer-events: none;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box.active::after {
  content: "Current Slide";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  padding-top: 65px;
  background: rgba(19, 18, 18, 0.55);
  color: #fff c2;
  font-weight: 600;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box:hover {
  outline: solid #0096db !important;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box.selected {
  outline: solid #0096db;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box .image {
  width: 100%;
  object-fit: cover;
  height: 120px;
}
#sitemap .sitemapModal .modal .content .slidesList .slide-box .title {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 10px;
  background: #0096db;
  color: white;
  margin: 0 !important;
  padding: 10px;
}
#sitemap .sitemapModal .modal .content .slidesList:hover .slide-box.selected {
  outline: none;
}
.text-component {
  width: 200px;
  height: 59px;
}
.image-component {
  width: 200px;
  height: 200px;
}
.card-component {
  width: 200px;
  height: 239px;
}
.container-component {
  width: 300px;
  height: 300px;
}
.popup-component {
  width: 300px;
  height: 300px;
}
.radio-component {
  width: 200px;
  height: 80px;
}
.checkbox-component {
  width: 200px;
  height: 86px;
}
.table-component {
  width: 300px;
  height: 82px;
}
.flipCard-component {
  width: 500px;
  height: 309px;
}
.button-component {
  width: 200px;
  height: 56px;
}
.slider-component {
  width: 200px;
  height: 50px;
  min-height: 40px !important;
}
.audio-component {
  width: 400px;
  height: 60px;
}
.video-component {
  width: 500px;
  height: 260px;
}
.input-component {
  width: 200px;
  height: 40px;
}
.graph-component {
  width: 400px;
  height: 399px;
}
.graph-component canvas {
  height: 100% !important;
}
.coverflow-component {
  width: 500px;
  height: 334px;
  overflow: hidden;
  padding:5px;
}
.carousel-component{
  overflow: hidden;
  padding:5px;
}
.carousel-component img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.extendedCard-component {
  width: 500px;
  height: 299px;
}
.tabGroup-component {
  width: 600px;
}

.cs-extendedCard {
  height: 100%;
  overflow: hidden;
  .cs-image {
    object-fit: scale-down !important;
    aspect-ratio: auto !important;
  }
}
.cs-tabGroup {
  overflow: hidden;
}
