.sendTestEmail {
  .formControl {
    label{
        color: #2e3a94;
    }
    label,
    input {
      display: block;
      width: 100%;
    }
    input {
      border: 0;
      outline: 0;
      width: 100%;
      margin-top: 10px;
      border-bottom: 2px solid #2e3a94;
      padding: 2px;
    }
  }
  .sendBtn{
    display: block;
    margin-left: auto;
    margin-top: 20px;
  }
}
