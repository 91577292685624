.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
}
.card2 {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 12px 32px 0px #a2abc826;
  padding: 16px;
}
.card.mb20 {
  margin-bottom: 20px;
}
.screen {
  position: relative;
  overflow-x: hidden;
  background: #eef3ff;
  outline: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.02),
    inset 0px -4px 24px rgba(188, 192, 221, 0.25);
  border-radius: 0px;
  padding: 15px;
  flex: 1;
}
